<template lang="pug">
.header_wrapper
  h1.headline.text-primary(v-if="$root.profile.role === 'admin'") {{ Object.keys(filtered_card).length }} Clients / {{ t.aum }} {{ format( '.3s€')(total_aum) }}
  .search
    input(type="search" v-model="fundSearch")
.funds_container
  article.fund_box(v-for="(userflow, key) in filtered_card" :key="key")
    div.fund_box__items
      span.text-xs {{t.fund}}
      h2.font_weight_medium.text-gray-600.text-lg.uppercase {{t[userflow.name] || userflow.name}}
    div.fund_box__items.mr-8
      span.text-xs {{t.benchmark}}
      p.fund_benchmark.text-gray-500 {{userflow.benchmark}}
    div.fund_box__items.ml-8
      span.text-xs {{t.updated_date}}
      p.fund_benchmark.text-gray-500 {{ userflow.date }}
    div.fund_box__items.ml-8
      span.text-xs {{t.aum}}
      p.fund_benchmark.text-gray-500 {{ format( '.3s' + userflow.currency)(+userflow.aum || 0) }}
    .fund_button_container.flex.space-x-1
      router-link(:to="'/' + userflow.name + '/performance'")
        button.fund_button__details Details
      router-link(:to="'/' + userflow.name + '/pdf?domain=' + new Date().minus('1 month').format('YYYY-MM')" target="_blank")
        button.fund_button.bg-gray-400.text-xs.leading-none PDF M-1
      router-link(:to="'/' + userflow.name + '/pdf'" target="_blank")
        button.fund_button.bg-gray-400.text-xs.leading-none PDF YTD

</template>

<script>
import { ref, computed } from 'vue'
export default {
  icon: 'ic_account_balance_wallet',
  setup() {
    const fundSearch = ref('')
    const filtered_card = computed(() =>
      ($root.db.userflows || {}).__.filter(d => ($root.profile.funds || []).includes(d.name) || $root.profile.role == 'admin').__.filter(
        item => item.name.toLowerCase().match(fundSearch.value.toLowerCase()) || $root.t[item.name]?.toLowerCase().match(fundSearch.value.toLowerCase()),
      ),
    )
    const total_aum = computed(() =>
      ($root.db.userflows || {}).__.map(d => +d.aum)
        .__.v()
        .__.filter()
        .sum(),
    )
    return {
      fundSearch,
      filtered_card,
      total_aum,
    }
  },
}
</script>
<style>
.headline {
  font-weight: 600;
  font-size: 28px !important;
}
.funds_length {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary);
}
.header_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: no-wrap;
}
.funds_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.fund_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--funds-padding);
  margin-bottom: var(--funds-padding);
  margin-right: var(--funds-padding);
  background: var(--white);
  color: var(--secondary);
  border-radius: var(--funds-radius);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}
.fund_box h3 {
  font-weight: bold;
  padding-bottom: var(--funds-content-padding);
  padding-left: 0px !important;
}
.fund_benchmark {
  color: var(--inactive);
  padding-bottom: var(--funds-content-padding);
}
.fund_button_container {
  display: flex;
  max-width: 200px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 86px;
  width: 100%;
}
.fund_button {
  padding: var(--funds-button-padding);
  border-radius: var(--funds-radius);
  color: white;
  transition: var(--transition);
}
.fund_button:hover {
  padding: var(--funds-button-padding);
  border-radius: var(--funds-radius);
  color: white;
  box-shadow: var(--shadow);
}
.fund_button a:hover,
.fund_button a:focus {
  color: white;
}
.fund_button__details {
  background: var(--primary) !important;
  padding: var(--funds-button-padding);
  border-radius: var(--funds-radius);
  color: white;
  transition: var(--transition);
}
.fund_button__details a,
.fund_button__details a:hover {
  color: white !important;
}

.fund_button__details:hover {
  background: var(--primary) !important;
  padding: var(--funds-button-padding);
  border-radius: var(--funds-radius);
  color: white;
  box-shadow: var(--shadow);
}
.fund_box__items {
  width: 27%;
}

@media only screen and (max-width: 769px) {
  .fund_box {
    width: 100%;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 600px) {
  .funds_length {
    padding-left: 16px !important;
  }
  .fund_box {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding: var(--funds-padding);
    margin-bottom: var(--funds-padding);
    margin-right: var(--funds-padding);
    background: var(--white);
    color: var(--secondary);
    border-radius: var(--funds-radius);
    box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
  }
  .fund_box .fund_box__items {
    width: fit-content;
  }

  .fund_box h2 {
    padding: 0 !important;
  }
  .fund_box span {
    font-size: 80% !important;
  }
  .fund_button__details {
    margin-left: 0 !important;
  }
}
.row {
  flex-wrap: wrap;
}
.row.right {
  flex: 1;
}
.row.right input {
  min-width: 400px;
}
.search {
  margin-right: 18px;
}
.search {
  margin-right: 18px;
}
input[type='search'] {
  border-width: 0px !important;
  padding-left: 2.5rem !important;
  border-radius: var(--funds-radius);
}
</style>
