<template lang="pug">
template(v-if="$root.db.config")
  page-header(
    :page="t[$root.screen.name] || $root.screen.name"
    :fund="t[$root.userflow.name] || $root.userflow.name"
    :benchmark="t[$root.userflow.benchmark] || $root.userflow.benchmark"
    :email="t[$root.userflow.contact_email] || $root.userflow.contact_email")
  .row.graph_box
    .block
      kpi-box(:label="t.performance" :value="format('.2%')(getPerformance)" :benchmark_label="t.benchmark" :benchmark_value="format('.2%')(getBenchmark)")
    .block
      kpi-box(:label="t.financial_product" :value="format( '.3s'+$root.userflow.currency)(gainLoss)")
    .block
      kpi-box(:label="t.aum" :value="format( '.3s'+$root.userflow.currency)(getAum)")
    .block
      kpi-box(:label="t.volatility" :value="format('.2%')(getVolatility_fund)" :benchmark_label="t.volatility_benchmark" :benchmark_value="format('.2%')(getVolatility_benchmark)")
  .row 
    button.button(@click="export_csv") {{ t.export_csv }}
    button.button(@click="export_excel") {{ t.export_excel }}
    template(v-if="$root.profile.role === 'admin'")
      span.button.ghost.pointer-events-none(:class="'!ml-auto'") Data Audit:
      a.button(:href="'https://fireauth.100m.io/app?path=' + $root.userflow.name + '/data_allocation.csv&project=cedrus&auth0_token=' + localStorage.idToken || localStorage.id_token") Contribution
      a.button(:href="'https://fireauth.100m.io/app?path=' + $root.userflow.name + '/data_performance.csv&project=cedrus&auth0_token=' + localStorage.idToken || localStorage.id_token") Performance
      a.button(:href="'https://fireauth.100m.io/app?path=' + $root.userflow.name + '/data_mapping.csv&project=cedrus&auth0_token=' + localStorage.idToken || localStorage.id_token") Mapping
      a.button(:href="'https://fireauth.100m.io/app?path=' + $root.userflow.name + '/trades.csv&project=cedrus&auth0_token=' + localStorage.idToken || localStorage.id_token") Trades
  .flex.flex-col
    accordion(v-for="(item, index) in table" 
      :key="index" 
      :group="t[item.group] || item.group", 
      :allocation="format('.2%')(item.allocation)", 
      :position="format( '.3s'+$root.userflow.currency)(item.position)", 
      :returns="format('.2%')(item.returns)", 
      :pnl="format(item.pnl === 0 ? '.0f'+ $root.userflow.currency : item.pnl > 1000 ? '.3s'+ $root.userflow.currency : '.2s'+ $root.userflow.currency)(item.pnl)", 
      :contribution="format('bp')(item.contribution)")
    
      accordion(v-for="(itemData, index) in item.data" :key="index" 
        :group="t[itemData.name] || itemData.name", 
        :allocation="format('.2%')(itemData.allocation)", 
        :position="format( '.3s'+$root.userflow.currency)(itemData.position)", 
        :returns="format('.2%')(itemData.returns)", 
        :pnl="format(itemData.pnl === 0 ? '.0f'+ $root.userflow.currency : itemData.pnl > 1000 ? '.3s'+ $root.userflow.currency : '.2s'+ $root.userflow.currency)(itemData.pnl)", 
        :contribution="format('bp')(itemData.contribution)"
        
      ) 
        .flex.flex-row.p-4
          .flex-col.flex-1.text-white.justify-around
            h1 {{t.analytics}} 
            .flex.p-4.text-white.justify-around
              ul.flex-1
                li.font-bold {{t.returns}}
                li.font-bold(v-if="$root.profile.role === 'admin' && itemData.quantity !== false") {{ t.share_number}}
                li.font-bold {{t.returns_last_month}} 
                li.font-bold {{t.performance}} {{t['5y']}}  
                li.font-bold {{t.aum_last_year_raw}} 
                li.font-bold {{t.benchmark}} 
                li.font-bold {{t.benchmark_last_month}}
                li.font-bold {{t.liquidity}} 
                li.font-bold {{t.volatility_yearly}} 
                li.font-bold {{t.pnl_realised}} 
                li.font-bold {{t.pnl_latent}} 
                li.font-bold {{t.max_drawdown}}
                li.font-bold {{t.performance_max}}
                li.font-bold {{t.performance_avg}}
                li.font-bold {{t.performance_min}}

              ul.flex-1
                li {{ format('.2%')(itemData.returns)}} 
                li(v-if="$root.profile.role === 'admin' && itemData.quantity !== false") {{ +itemData.quantity.toFixed(2) }}
                li {{ format('.2%')(itemData.returns_last_month)}} 
                li {{ format('.2%')(itemData.returns_5y) }} 
                li {{ format( '.3s'+$root.userflow.currency)(itemData.aum_last_year_raw)}} 
                li {{ format('.2%')(itemData.benchmark)}} 
                li {{ format('.2%')(itemData.benchmark_last_month)}}
                li {{ itemData.liquidity}} 
                li {{ format('.2%')(itemData.volatility_yearly)}} 
                li {{ format(itemData.pnl_realised === 0 ? '.0f'+ $root.userflow.currency : itemData.pnl_realised > 1000 ? '.3s'+ $root.userflow.currency : '.2s'+$root.userflow.currency)(itemData.pnl_realised)}} 
                li {{ format(itemData.pnl_latent === 0 ? '.0f'+ $root.userflow.currency  : itemData.pnl_latent > 1000 ? '.3s'+$root.userflow.currency : '.2s'+$root.userflow.currency)(itemData.pnl_latent)}} 
                li {{ format('.2%')(getMaxDrawdown(itemData.isin))}} 
                li {{ format('.2%')(kpiMax(performanceData(itemData), r => r.fund))}} 
                li {{ format('.2%')(kpiAvg(performanceData(itemData), r => r.fund))}} 
                li {{ format('.2%')(kpiMin(performanceData(itemData), r => r.fund))}} 

          .flex-col.flex-1.text-white.justify-around.flex-1
            h1 {{t.characteristics}} 
            .flex.p-4.text-white.justify-around
              ul.flex-1
                li.font-bold {{t.caceis_isin || 'caceis_isin' }}
                li.font-bold {{t.manager || 'manager' }} 
                li.font-bold {{t.zone_geo || 'zone_geo' }} 
                li.font-bold {{t.secteur || 'secteur' }} 
                li.font-bold {{t.label_isr || 'isr' }} 
                li.font-bold {{t.devise || 'devise' }} 
                li.font-bold(v-if="$root.profile.role === 'admin'") {{t.isin_cedrus || 'isin_cedrus' }} 
              ul.flex-1
                li {{ itemData.caceis_isin || 'NA'}} 
                li {{ itemData.manager || 'NA'}} 
                li {{ itemData.zone_geo || 'NA' }}
                li {{ itemData.secteur || 'NA' }}
                li {{ itemData.isr || 'NA' }}
                li {{ itemData.devise || 'NA'}} 
                li(v-if="$root.profile.role === 'admin'") {{ itemData.isin || 'NA' }}

        .flex
          .block_canvas.mt-8
            h2 {{t.allocation}}
            cjs-timeseries-chart(:data="allocationData(itemData)" :options="allocationOptions")
              template(v-slot:tooltip="{tooltip}")
                table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatMillionRows(tooltip.rows)")
          .block_canvas.ml-2.mt-8
            h2 {{t.performance}}
            cjs-timeseries-chart(:data="performanceData(itemData)" :options="performanceOptions")
              template(v-slot:tooltip="{tooltip}")
                table-tooltip(v-if="tooltip.shown" :title="formatTitle(tooltip.title)" :rows="formatPercentRows(tooltip.rows)" )

          .block_canvas.ml-2.mt-8
            h2 {{t.returns}}
            cjs-bar-chart(:data="returnsData(itemData)" :options="returnsOptions")
              template(v-slot:tooltip="{tooltip}")
                table-tooltip(v-if="tooltip.shown" :title="tooltip.title" :rows="formatPercentRows(tooltip.rows)")
          .block_canvas.ml-2.mt-8
            h2 {{t.risk_reward}}
            cjs-bubble-chart(:data="riskData(itemData)" :options="riskOptions(itemData)")
              template(v-slot:legend="{legend}")
                div.hidden no-legend
              template(v-slot:tooltip="{tooltip}")
                .tooltip-table.shadow.border-t.absolute.top-0.right-0.bg-white.border-t-primary.pointer-events-none(class="min-w-[180px]" v-if="tooltip.shown")
                  div.text-gray-500.border-b.border-b-gray-200
                    .flex.flex-row.items-center.p-1
                      .h-2.w-2.rounded-full.border-transparent.mr-2
                      .text-xs {{ item.data[tooltip.title - 1].name }}
                  div
                    div.flex.py-1.p-2.justify-between(v-for="(row, k) in tooltip.rows.__.filter(row => row.label !== 'r')" :key="k")
                      .flex.flex-row.items-center
                        .h-2.w-2.rounded-full.border.border-transparent.mr-2(:style="{ background: row.color, borderColor: row.borderColor }")
                        .text-sm {{ { x: 'Volatility', y: 'Returns', r: 'Weight' }[row.label] }}
                      .ml-2
                        .font-bold.text-sm {{ Math.round(row.value * 10000) / 100 + '%' }}
</template>

<script>
import * as common from '../../composables/common'
import useKpis from '../../composables/useKpis'
import useDetails from '../../composables/useDetails'

export default {
  icon: 'ic_storage',
  datasets: ['contribution', 'performance', 'mapping', 'trades', 'movements', 'allocation'],
  setup() {
    return {
      ...common,
      ...useKpis(),
      ...useDetails(),
    }
  },
}
</script>
